<template>
  <div class="AccountSignupPartnerView">
    <div class="main">
      <div class="partnerIcon">
        <img
          class="memolifeLogo"
          src="@/assets/images/signup-partner/memolife-heart.svg">
        <img
          v-if="partner === 'storebrand'"
          src="@/assets/images/signup-partner/storebrand-logo.svg">
        <img
          v-if="partner === 'superoffice'"
          src="@/assets/images/signup-partner/superoffice-logo.svg">
        <img
          v-else-if="partner === 'techdata'"
          class="icon__techdata"
          src="@/assets/images/signup-partner/techdata-logo.jpg">
        <img
          v-else-if="partner === 'aim2north'"
          class="icon__aim2north"
          src="@/assets/images/signup-partner/aim2north-logo.png">
        <img
          v-else-if="partner === 'technipfmc'"
          class="icon__technipfmc"
          src="@/assets/images/signup-partner/technipfmc-logo.png">
        <img
          v-else-if="partner === 'xledger'"
          class="icon__xledger"
          src="@/assets/images/signup-partner/xledger-logo.png">
        <img
          v-else-if="partner === 'sats'"
          class="icon__sats"
          src="@/assets/images/signup-partner/sats-logo.png">
        <img
          v-else-if="partner === 'tekna'"
          class="icon__tekna"
          src="@/assets/images/signup-partner/tekna-logo.svg">
      </div>
      <div class="spacer spacer--top" />
      <div class="title">
        {{ $t('title') }}
      </div>

      <div class="widthHelper">
        <form @submit.prevent="registerByEmail()">
          <FormGroup
            v-model.trim="name"
            name="name"
            :label="$t('account.formLabels.name')"
            :v="$v.name"
            autocomplete="name"
            @input="resetErrors()" />

          <FormGroup
            v-model.trim="email"
            name="email"
            :label="$t('account.formLabels.email')"
            :v="$v.email"
            autocomplete="email"
            @input="resetErrors()" />

          <div class="submitButtonContainer">
            <div
              v-show="error"
              class="errorBox">
              <div v-if="errorMsg">
                {{ errorMsg }}
              </div>
              <div
                v-else
                v-t="'account.validationMessages.general'" />
            </div>
            <SubmitButton
              name="signup"
              class="submitButton"
              :label="$t('emailSignupButton')"
              :working="workingEmail"
              :disabled="$v.$invalid" />
          </div>
        </form>

        <template v-if="!hideFacebookLogin">
          <DividerWithText :label="$t('general.or')" />

          <div
            v-if="fbError"
            class="errorBox">
            An error occured while trying to sign up through Facebook. Please try again or
            <a
              href="#"
              @click="openSupport()">contact customer support</a>.
          </div>
          <SubmitButton
            class="facebookButton button"
            :label="$t('facebookSignupButton')"
            :working="workingFacebook"
            @click.native="registerThroughFacebook()" />
        </template>
      </div>

      <div class="spacer spacer--bottom" />

      <div
        v-if="!hideLoginLink"
        class="links">
        <span v-t="'alreadyMember'" />&nbsp;
        <span
          class="loginLink --global-clickable"
          @click="gotoLogin()">{{ $t('alreadyMemberLogin') }}</span>
      </div>
    </div>

    <div
      class="side"
      :class="[`side--${partner}`]" />
  </div>
</template>

<translations>
  title: 'Welcome to Memolife Partners'
  title_no: 'Velkommen til Memolife Partners'

  alreadyMember: 'Already a member?'
  alreadyMember_no: 'Allerede medlem?'
  alreadyMemberLogin: 'Login'
  alreadyMemberLogin_no: 'Logg inn'

  emailSignupButton: 'Signup'
  emailSignupButton_no: 'Meld deg inn'

  facebookSignupButton: 'Signup with Facebook'
  facebookSignupButton_no: 'Meld deg inn med Facebook'
</translations>

<script>
import FormGroup from '../components/FormGroup';
import SubmitButton from '../components/SubmitButton';
import { required, email } from 'vuelidate/lib/validators';
import DividerWithText from '../components/DividerWithText';
import { v4 as uuidv4 } from 'uuid';

export default {
    components: { FormGroup, SubmitButton, DividerWithText },
    props: {
        voucherAfterSignup: {
            type: Boolean,
            default: false
        },
        hideLoginLink: {
            type: Boolean,
            default: false
        },
        hideFacebookLogin: {
            type: Boolean,
            default: false
        },
        partner: {
            type: String
        },
        voucher: {
            type: String
        },
        language: {
            type: String
        },
        memoransierForward: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            workingFacebook: false,
            workingEmail: false,
            from: 'fromMain',
            name: '',
            email: '',
            errorMsg: '',
            error: false,
            fbError: false
        };
    },
    computed: {
        currentLocale() {
            return this.$store.getters['moduleApp/locale'];
        }
    },

    mounted() {
        this.plan = this.$route.params.plan;
        this.currency = this.$route.params.currency;
        const lang = this.language || this.$route.params.language || this.$route.query.language;
        if (lang) {
            this.$store.dispatch('moduleApp/setLocale', lang);
        }
    },
    methods: {
        setLocale(language) {
            this.$store.dispatch('moduleApp/setLocale', language);
        },
        resetErrors() {
            this.errorMsg = '';
            this.error = false;
            this.fbError = false;
        },
        async registerByEmail() {
            this.workingEmail = true;

            let nameparts = this.name.split(' ');
            let firstName = nameparts.shift();
            let lastName = nameparts.join(' ');
            let language = this.$store.getters['moduleApp/locale'];
            let currency = language === 'no' ? 'nok' : 'usd';
            try {
                await this.$store.dispatch('moduleAuth/register', {
                    email: this.email,
                    username: this.email,
                    password: uuidv4(),
                    firstName,
                    lastName,
                    language,
                    currency
                });
            } catch (err) {
                this.errorMsg = err.message;
                if (this.errorMsg === 'Username already taken') {
                    this.errorMsg = 'Email already taken';
                }
                this.error = true;
                return;
            } finally {
                this.workingEmail = false;
            }
            this.onSignupSuccess('email');
        },
        async registerThroughFacebook() {
            this.workingFacebook = true;
            try {
                await this.$store.dispatch('moduleAuth/authenticate', 'facebook');
            } catch (err) {
                this.fbError = true;
                return;
            } finally {
                this.workingFacebook = false;
            }
            this.onSignupSuccess('facebook');
        },

        async forwardToActiveMemoransier() {
            if (this.$store.getters['moduleMemoransier/publishedGames'].length === 0) {
                await this.$store.dispatch('moduleMemoransier/fetchAll');
            }
            const game = this.$store.getters['moduleMemoransier/publishedGames'].find(g => g.role === this.voucher);
            if (game) {
                this.$router.push({
                    name: 'MemoransierLobby',
                    params: { gameId: game.id }
                });
            } else {
                this.$router.push({ path: '/' });
            }
        },

        forward() {
            if (this.memoransierForward) {
                this.forwardToActiveMemoransier();
            } else if (this.voucher) {
                this.$router.push({ path: '/' });
            } else if (this.voucherAfterSignup) {
                this.$router.push({ name: 'AccountVoucherView' });
            } else if (this.plan !== undefined) {
                this.$router.push({
                    name: 'AccountCheckoutView',
                    params: { plan: this.plan }
                });
            } else {
                this.$router.push({ path: this.$route.query.next || '/' });
            }
        },

        async onSignupSuccess(provider) {
            window.dataLayer.push({ event: 'signup', provider: provider });
            // this.storage.setItem('product', this.product);
            if (this.voucher) {
                await this.registerWithVoucher(this.voucher);
            }
            this.forward();
        },

        async registerWithVoucher(voucher) {
            this.workingEmail = true;

            try {
                // await this.$store.dispatch('moduleAuth/subscribe', {
                //   subscription: { 'trial': true }, // plan_id ?
                //   customer: {},
                //   coupon: voucher
                // })
                await this.$store.dispatch('moduleAuth/updateMe', { roles: [voucher] });
            } catch (err) {
                console.log('registerWithVoucher err', err); // eslint-disable-line no-console
                return;
            } finally {
                this.workingEmail = false;
            }
        },
        gotoLogin() {
            let next = this.$route.query.next;
            if (this.voucher) {
                next = `/partner/${this.voucher}`;
            }
            this.$router.push({ name: 'AccountLoginView', query: { next } });
        },
        openSupport() {
            this.$intercom.open();
        }
    },
    validations: {
        name: {
            required
        },
        email: {
            required,
            email
        },
    }
};
</script>

<style lang="scss" scoped>
.AccountSignupPartnerView {
    background-color: white;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title {
    font-weight: 600;
    font-size: 140%;
    text-align: center;
}

.widthHelper {
    width: 100%;
    max-width: 30em;
    margin-top: 2em;
}

.partnerIcon {
    text-align: center;
    // background-color: red;
    // width: 7em;
    // height: 2em;
    max-width: 20em;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon__techdata {
    height: 2.5em;
}
.icon__aim2north {
    height: 2.5em;
}
.icon__technipfmc {
    height: 2.5em;
}
.icon__xledger {
    height: 3em;
}
.icon__sats {
    height: 3em;
}
.icon__tekna {
    height: 3em;
}
.memolifeLogo {
    height: 2em;
    margin-right: 1em;
}
.errorBox {
    padding: 1em;
    background-color: #edc8c5;
    color: #c38a8a;
    margin-bottom: 1em;
}

.spacer {
    flex: 1;
}
.spacer--top {
    min-height: 2em;
}
.spacer--bottom {
    min-height: 2em;
}

.submitButtonContainer {
    margin-top: 2em;
}
.submitButton {
    // width: 100%;
    margin: auto;
    display: flex;
}

.button {
    background-color: #3b5998;
    color: white;
    padding: 1em 2em;
    border-radius: 2em;
    text-align: center;
}

.FormGroup {
    &:not(:first-child) {
        margin-top: 1em;
    }
}

.side {
    // height: 100%;
    // display: flex;
    // flex-direction: column;
    //
    // background-color: #FA3D38;
    //
    // background-image: url('../../../assets/images/signup-partner/superoffice-owl.svg');
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: 80%;
}
.side--superoffice {
    background-color: #fa3d38;

    background-image: url('../../../assets/images/signup-partner/superoffice-owl.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
}
.side--techdata {
    background-size: cover;
    background-image: url('../../../assets/images/signup-partner/techdata-banner.png');
}
.side--aim2north {
    background-size: cover;
    background-image: url('../../../assets/images/signup-partner/aim2north-banner.jpg');
}
.side--storebrand {
    background-size: cover;
    background-image: url('../../../assets/images/signup-partner/aim2north-banner.jpg');
}
.side--technipfmc {
    background-size: cover;
    background-image: url('../../../assets/images/signup-partner/technipfmc-banner.jpg');
}
.side--xledger {
    background-size: cover;
    background-image: url('../../../assets/images/signup-partner/xledger-banner.png');
    background-position: center;
}
.side--sats {
    background-size: cover;
    background-image: url('../../../assets/images/signup-partner/sats-banner.jpg');
    background-position: center;
}
.side--tekna {
    background-size: cover;
    background-image: url('../../../assets/images/signup-partner/tekna-banner.jpg');
    background-position: center;
}

.links {
    a {
        text-decoration: none;
        color: #5fcf80;
    }
}
.loginLink {
    color: #5fcf80;
}
.facebookButton {
    margin: auto;
    display: flex;
}

// ----------------------------------
// footer
// ----------------------------------

.footer {
    border: 1px solid red;
}

.viewFooter {
    margin-top: 1em;
    text-align: center;
    color: black;
}

.legal {
    color: rgba(black, 0.7);
}

.languageSwitch {
    display: flex;
    justify-content: center;
    align-items: center;
}

.languageSwitchLanguage {
    margin-top: 1em;
    cursor: pointer;
    margin-right: 1em;
    // padding-bottom: 4px;
    // border-bottom: 2px solid transparent;
    color: rgba(black, 0.7);
    display: inline-flex;
    align-items: center;

    .svg-icon {
        $size: 0.5em;
        width: $size;
        height: $size;
        margin-right: 0.3em;
        display: none;
    }

    &.active {
        .languageSwitchIndicator {
            &::after {
                content: '';
                display: block;
                $size: 6px;
                width: $size;
                height: $size;
                background-color: rgba(black, 0.7);
                border-radius: 50%;
            }
        }
    }
    &:not(.active) {
        .svg-icon {
            display: none;
        }
    }
}
.languageSwitchIndicator {
    $size: 1em;
    display: inline-flex;
    width: $size;
    height: $size;
    border: 1px solid rgba(black, 0.5);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-right: 0.3em;
}

// ----------------------------------
// Responsive
// ----------------------------------

@include media('>=600px') {
    .AccountSignupPartnerView {
        display: flex;
    }
    .main,
    .side {
        // flex: 1;
    }
    .side {
        // order: -1;
        width: 60%;
    }
    .main {
        min-width: 26em;
        flex: 1;
        padding: 1em 2em;
    }
}
@include media('<600px') {
    .AccountSignupPartnerView {
        background-color: white !important;
        background: none;
        overflow-y: auto;
    }
    .side {
        display: none;
    }
    .main {
        padding: 1em 2em;
        height: 100%;
    }
}
</style>
